<template>
    <div>
      <div class="all-feed-box-card pt-2">
          <div class="shimmerBG title-line-feedback"></div>
          <div class="shimmerBG title-line-feedback"></div>
          <div class="shimmerBG title-line-feedback"></div>
          <div class="shimmerBG title-line-feedback"></div>
          <div class="shimmerBG title-line-feedback"></div>
          <div class="shimmerBG title-line-feedback"></div>
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "FeedbackLoader",
  }
  </script>
  
  <style lang="scss" >
  @import "../../assets/scss/component-css/feedback.css";
  </style>